import { Header } from 'features-unauthenticated/header';
import { Box, Flex, Text } from '@chakra-ui/react';
import Resume from './Resume';
import Details from './Details';

function OffresEtTarifs() {
  return (
    <Flex
      direction={{ base: 'column' }}
      align="center"
      maxW={{ xl: '1200px' }}
      m="0 auto"
    >
      <Header
        displayAuthAccessCTA={true}
        displayHublot={true}
        isIntern={true}
      />
      <Text fontSize="2xl" as="h1" fontWeight="bold" marginTop={10}>
        Nos offres et tarifs
      </Text>
      <Text fontSize="lg" marginTop={5}>
        Découvrez nos formules
      </Text>
      <Resume />

      <Details />
      <Box h={100} minH={100}>
        &nbsp;
      </Box>
    </Flex>
  );
}
export default OffresEtTarifs;
