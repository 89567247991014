import React from 'react';
import { Link, Link as ReactRouterLink } from 'react-router-dom';
import { Flex, Image, Button, useBreakpointValue, Box } from '@chakra-ui/react';
import LogoCompas from 'images/logo_compas.png';
import LogoHublotSVG from 'images/logo_hublot_couleur.svg';
import LogoCorailSVG from 'images/corail/logo_corail_couleur.svg';

export function Header({
  displayAuthAccessCTA = false,
  displayHublot = false,
  isIntern = false,
}) {
  const isMobile = useBreakpointValue({ base: true, md: false });
  const LogoApp = () => {
    switch (process.env.REACT_APP_COMPAS_CIBLE) {
      case 'CORAIL':
        return <Image src={LogoCorailSVG} alt='"logo Hublot' />;
      default:
        return <Image src={LogoHublotSVG} alt='"logo Hublot' />;
    }
  };

  return (
    <Flex
      direction={{ base: 'column', md: 'row' }}
      align="center"
      justify="space-between"
      wrap="wrap"
      w="100%"
      p={{ base: 1, md: 3 }}
      borderBottomWidth={'2px'}
      borderBottomColor={'hublot.bleuClair'}
      minH={'80px'}
    >
      {!isIntern && (
        <Flex
          width={{ base: '100px', md: '192px' }}
          alignItems={'center'}
          href="http://www.lecompas.fr"
          target={'_blank'}
          rel="noreferrer"
          title="Un site www.lecompas.fr"
          as="a"
        >
          <Image src={LogoCompas} alt='"logo Compas' />
        </Flex>
      )}
      {displayHublot && (
        <Flex
          width={{ base: '100px', md: '192px' }}
          alignItems={'center'}
          to={'..'}
          rel="noreferrer"
          title="Un site www.hublot.lecompas.fr"
          as={Link}
        >
          <LogoApp />
        </Flex>
      )}

      {displayAuthAccessCTA && !isMobile && (
        <Flex gap={4} direction={{ base: 'column', md: 'row' }}>
          <Button
            as={ReactRouterLink}
            to="/offres-et-tarifs"
            p="4"
            variant={'outline'}
          >
            Offres et tarifs
          </Button>
          <Button
            as={ReactRouterLink}
            to="/inscription"
            p="4"
            variant={'outline'}
            // border="1px solid"
            // borderColor="hublot.jaune"
            // backgroundColor={'hublot.jaune'}
            // color="black"
            // _hover={{ color: 'white', bgColor: 'hublot.bleuFonce' }}
          >
            Créez gratuitement votre accès Découverte
          </Button>
          <Button
            as={ReactRouterLink}
            to="/app"
            p="4"
            border="1px solid"
            borderColor="hublot.bleuElectrique"
          >
            Accès abonnés
          </Button>
        </Flex>
      )}
    </Flex>
  );
}
